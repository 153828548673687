import React, { useState, useEffect, useRef } from 'react';
import Hls from 'hls.js';

const Stream = () => {
    const [hasStream, setHasStream] = useState(false);
    const videoRef = useRef(null);
    const streamUrl = 'https://stream.novoe-serdce.ru/hls/stream.m3u8';

    useEffect(() => {
        const checkStream = async () => {
            try {
                const response = await fetch(streamUrl, {
                    method: 'HEAD',
                });
                if (response.ok) {
                    setHasStream(true);
                } else {
                    setHasStream(false);
                }
            } catch (error) {
                console.error('Ошибка при проверке потока:', error);
                setHasStream(false);
            }
        };

        checkStream();
    }, []);

    useEffect(() => {
        if (hasStream && videoRef.current) {
            const hls = new Hls();
            hls.loadSource(streamUrl);
            hls.attachMedia(videoRef.current);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                // Убедитесь, что видео не начинает воспроизводиться автоматически
                videoRef.current.pause();
            });

            return () => {
                hls.destroy();
            };
        }
    }, [hasStream]);

    return (
        <div>
            <h3>Онлайн трансляция богослужения</h3>
            {hasStream ? (
                <video
                    ref={videoRef}
                    className="video-js vjs-default-skin"
                    controls
                    preload="auto"
                    width="640"
                    height="360"
                    onClick={() => {
                        if (videoRef.current) {
                            videoRef.current.play().catch((error) => {
                                console.error('Ошибка при воспроизведении:', error);
                            });
                        }
                    }}
                >
                    {/* Видео будет загружено и воспроизведено автоматически при нажатии */}
                </video>
            ) : (
                <p>Трансляция в данный момент недоступна.</p>
            )}
        </div>
    );
};

export default Stream;
